import React from 'react'
import { Div, P } from '../styled-components'
import GoalsSvg from "../../assets/goals.svg"
import Vision from "../../assets/vision.svg"

const Goals = () => (
  <Div
    padding="80px 20px"
    width="100%"
    display="flex"
    align_items="center"
    justify_content="center"
    background_color="#23227B"
    flexDirection960="column"
  >
    <Div
      margin="0 80px 0 0"
      display="flex"
      flexDirection="column"
      align_items="center"
      justify_content="center"
      margin960="0 0 80px"
    >
      <GoalsSvg alt="goals" />
      <P
        margin="20px 0 15px"
        fontSize="26px"
        color="#69b02a"
        textAlign="center"
      >
        Goals
      </P>
      <Div
        width="40px"
        height="2px"
        background_color="#69B02A"
      />
      <P
        maxWidth="380px"
        margin="15px 0 20px"
        fontSize="18px"
        color="#fff"
        textAlign="center"
      >
        PREMIER ENERGY SOLUTIONS, Inc. will set the standards in the industry by achieving our goals in...
      </P>
      <P
        maxWidth="381px"
        margin="0 0 20px"
        fontSize="18px"
        color="#fff"
        textAlign="center"
      >
        * Becoming the best service provider in our specialties in the industry in the State of Florida.
      </P>
      <P
        maxWidth="354px"
        margin="0 0 20px"
        fontSize="18px"
        color="#fff"
        textAlign="center"
      >
        * Delivering our customers their systems at their highest performance possible.
      </P>
      <P
        maxWidth="372px"
        margin="0"
        fontSize="18px"
        color="#fff"
        textAlign="center"
      >
        * Providing our customers, the service and support needed to achieve their goals.
      </P>
    </Div>
    <Div
      display="flex"
      flexDirection="column"
      align_items="center"
      justify_content="center"
    >
      <Vision
        alt="vision"
      />
      <P
        margin="20px 0 15px"
        fontSize="26px"
        color="#69b02a"
        textAlign="center"
      >
        Our Vision
      </P>
      <Div
        width="40px"
        height="2px"
        background_color="#69B02A"
      />
      <P
        maxWidth="397px"
        margin="15px 0 20px"
        fontSize="18px"
        color="#fff"
        textAlign="center"
      >
        PREMIER ENERGY SOLUTIONS, Inc. will become the most useful tool available for our costumer in providing and implementing solutions to their everyday challenges in the field.
      </P>
      <P
        maxWidth="349px"
        margin="0 0 20px"
        fontSize="18px"
        color="#fff"
        textAlign="center"
      >
        We will deliver in the most efficient, honest and versatile way to fulfill our customer needs while providing a friendly working environment.
      </P>
      <P
        maxWidth="373px"
        margin="0"
        fontSize="18px"
        color="#fff"
        textAlign="center"
      >
        Excellence is achieved in the pursuit of perfection… Inspired in a sphere, shaped from within…
      </P>
    </Div>
  </Div>
)

export default Goals