import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Div, P, Span } from '../styled-components'

const ServiceProvided = () => {
  const data = useStaticQuery(graphql`
    query {
      teamImage: file(relativePath: { eq: "home-banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Div
      padding="50px 10px"
      width="100%"
      display="flex"
      flexDirection="column"
      align_items="center"
      justify_content="center"
    >
      <P
        maxWidth="652px"
        margin="0"
        fontSize="18px"
        color="#666"
        textAlign="center"
      >
        Created in 2011, Premier Energy Solutions has the mission to be the best service provider in the commercial, industrial, medical device and pharmaceutical industry.
      </P>
      <P
        margin="30px 0 20px"
        fontSize="32px"
        fontWeight="bold"
        color="#666"
        textAlign="center"
      >
        PREMIER ENERGY SOLUTIONS
      </P>
      <Div
        width="280px"
        height="2px"
        background_color="#69B02A"
      />
      <P
        margin="20px 0"
        fontSize="18px"
        color="#666"
        textAlign="center"
      >
        * AABC Certified Firm in Air and Hydronic System Testing, Adjusting and Balance of Environmental Systems.
      </P>
      <P
        margin="0 0 15px"
        fontSize="26px"
        fontWeight="bold"
        color="#666"
        textAlign="center"
      >
        Business Service Provided
      </P>
      <P
        maxWidth="786px"
        margin="0 0 15px"
        fontSize="18px"
        color="#666"
        textAlign="center"
      >
        <Span fontSize="18px" fontWeight="bold" color="#666">PREMIER ENERGY SOLUTIONS </Span>
          is a company dedicated to providing the best service to the Industrial and Commercial Areas in the state of Florida and the Caribbean in the following specialties:
      </P>
      <P
        margin="0"
        fontSize="20px"
        fontWeight="bold"
        color="#666"
        textAlign="center"
      >
        Air and Hydronic Testing, Adjusting and Balance of HVAC Systems
      </P>
      <P
        maxWidth="739px"
        margin="0 0 15px"
        fontSize="18px"
        color="#666"
        textAlign="center"
      >
        Every facility must meet the design intent of the engineers and architect involved in the development of it. It is our job to knowledge, experience and tools to adjust the HVAC Systems in those facilities as per the design intent, drawings and specifications.
      </P>
      <P
        maxWidth="822px"
        margin="0 0 15px"
        fontSize="18px"
        color="#666"
        textAlign="center"
      >
        By doing so, deliver each system at its highest pick of performance with the least energy consumption possible. It is our compromise to report on any job completed, honest results obtained from our test and our expert opinion on what must be done, if necessary, to increase system performance.
      </P>
      <P
        margin="0 0 30px"
        fontSize="18px"
        color="#666"
        textAlign="center"
      >
        No job will be completed until we have push to the limit to deliver the most efficient system possible.
      </P>
      <Div
        width="700px"
        height="450px"
        width960="290px"
        height960="290px"
      >
      <Img
        fluid={data.teamImage.childImageSharp.fluid}
        style={{
          width: "100%",
          height: "100%"
        }}
      />
      </Div>
    </Div>
  )
}

export default ServiceProvided